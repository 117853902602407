/*
 * @Author: linghujie 391103334@qq.com
 * @Date: 2022-11-01 14:05:26
 * @LastEditors: linghujie 391103334@qq.com
 * @LastEditTime: 2022-11-02 09:28:31
 * @FilePath: \shared-wireless-charging-data-big-screen\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';

import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies;
Vue.use(VueCookies)

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/utils/flexible.js';

// 引入全局css
import './assets/scss/style.scss';



//引入echart
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
// import 'echarts/map/js/china.js'

Vue.config.productionTip = false;


new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');