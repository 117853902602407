

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    redirect: '/pc'  // 默认是pc端
}, {
    path: '/pc',
    name: 'pc',
    component: () => import('../views/pc/index'),

},
{
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/mobile/index'),

}]
async function getTerminalType() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return 'mobile';
    } else if (/(Android)/i.test(navigator.userAgent)) {
        return 'mobile';
    } else {
        return 'pc';
    }
}
const router = new VueRouter({
    routes
})

router.beforeEach(async (to, from, next) => {
    let terminalType = await getTerminalType();
    if (terminalType === 'mobile' && to.fullPath.indexOf('/pc') !== -1) {
        await next({ path: '/mobile' })
    } else if (terminalType === 'pc' && to.fullPath.indexOf('/mobile') !== -1) {
        await next({ path: '/pc' })
    } else {
        await next()
    }
});

export default router